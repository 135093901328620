import getConfig from "next/config";

const {publicRuntimeConfig} = getConfig();

export interface IOrganizationAdress {
  addressLocality: string;
  postalCode: string;
  streetAddress: string;
  addressCountry: string;
  addressRegion: string;
}
interface IOrganizationSchema {
  name: string;
  address: IOrganizationAdress;
  email: string;
  vatID: string;
  telephone: string;
}

export const organizationSchema = ({
  name,
  address,
  email,
  vatID,
  telephone
} : IOrganizationSchema) => {
  return {
    '@type': 'Organization',
    name,
    'address': {
      '@type': 'PostalAddress',
      ...address
    },
    areaServed: [
      {
        '@type': 'Country',
        'name': 'Austria',
      },
      {
        '@type': 'Country',
        'name': 'Germany',
      },
    ],
    email,
    vatID,
    telephone,
    'logo': {
      '@type': 'ImageObject',
      'url': `${publicRuntimeConfig.appBaseUrl}/static/images/digando-logo.svg`,
    },
    'image': {
      '@type': 'ImageObject',
      'url': `${publicRuntimeConfig.appBaseUrl}/static/images/digando-seo.jpg`,
    },
    url: publicRuntimeConfig.appBaseUrl
  }
};
