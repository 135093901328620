import SEO from '../components/seo/seo';
import React, { FC } from 'react';
import { ICanonical } from '../interfaces/canonical.interface';
import { organizationSchema } from '../schema.org/digando-organization.schema';
import { StrapiImageFragment } from '../@types/codegen/graphql';
import { useTranslation } from 'next-i18next';
import { isFeatureFlagEnabled } from '../lib/environment';


export interface IStrapiSeo {
  pageTitle: string;
  seo?: {
    title: string;
    description: string;
    url: string;
    image: StrapiImageFragment & { copyright?: string } | null;
    canonicals: ICanonical[];
    datePublished: string;
    dateModified: string;
    hideFromSitemapAndIndex?: boolean;
    origLocationName?: string;
  };
  resolverData?: {
    [key: string]: string;
  };
  schemaOrg?: {
    enable?: boolean;
    type?: 'BlogPosting' | 'Article';
    author?: {
      name: string;
      jobTitle: string;
      description: string;
      image: string;
      altText: string;
    };
  };
}

export const StrapiSeo: FC<IStrapiSeo> = (props) => {
  const [t] = useTranslation('schema-org');
  if (!props.seo) {
    return null;
  }

  const resolveData = (text: string): string => {
    if (!props.resolverData || 'string' !== typeof text) {
      return text;
    }

    if (0 === Object.keys(props.resolverData).length) {
      return text;
    }

    const keys = Object
      .keys(props.resolverData)
      .map(key => `{{${key}}}`);
    const regex = new RegExp(keys.join('|'), 'gi');

    text = text.replace(regex, function(matched) {
      const key: string = matched.substring(
        matched.lastIndexOf('{{') + 2,
        matched.lastIndexOf('}}'),
      );

      return props.resolverData?.[key] ?? text;
    });

    return `${text}`;
  };

  const structuredJSONImage = [];

  if (props.seo.image?.url) {
    structuredJSONImage.push(props.seo.image?.url);
  }
  const organization = organizationSchema({
    name: t('organization.name'),
    address: {
      addressLocality: t('organization.address.addressLocality'),
      addressRegion: t('organization.address.addressRegion'),
      postalCode: t('organization.address.postalCode'),
      streetAddress: t('organization.address.streetAddress'),
      addressCountry: t('organization.address.addressCountry'),
    },
    email: t('organization.email'),
    vatID: t('organization.vatID'),
    telephone: t('organization.telephone'),
  });
  let structuredAuthorJSON: Record<string, unknown> = organization;

  if (props.schemaOrg?.author) {
    const author = props.schemaOrg?.author;

    structuredAuthorJSON = {
      '@type': 'Person',
      'name': author.name,
      email: 'info@digando.com',
      vatID: 'ATU74452157',
      telephone: '0800 722 4433',
      jobTitle: author.jobTitle,
      'image': {
        '@type': 'ImageObject',
        'url': author.image,
      },
    };
  }

  const structuredJSON = JSON.stringify(
    {
      '@context': 'https://schema.org',
      '@type': props?.schemaOrg?.type ?? 'Article',
      'mainEntityOfPage': {
        '@type': 'WebPage',
        '@id': props.seo.url,
      },
      'author': structuredAuthorJSON,
      'isAccessibleForFree': true,
      'headline': resolveData(props.seo.title ?? props.pageTitle),
      'image': structuredJSONImage,
      'datePublished': props.seo.datePublished,
      'dateModified': props.seo.dateModified,
      'description': resolveData(props.seo.description),
      'publisher': organization,
    },
  );

  if (undefined == props.seo.hideFromSitemapAndIndex) {
    props.seo.hideFromSitemapAndIndex = false;
  }

  return (
    <SEO
      pageTitle={resolveData(props.seo.title ?? props.pageTitle)}
      title={resolveData(props.seo.title ?? props.pageTitle)}
      description={resolveData(props.seo.description)}
      url={props.seo.url}
      canonicals={props.seo.canonicals}
      image={{
        url: props.seo.image?.url ?? '',
        alt: resolveData(props.seo.image?.alternativeText ?? props.seo.title),
      }}
      structuredJSON={((props.schemaOrg?.enable ?? false) && isFeatureFlagEnabled('isSchemaMarkupEnabled')) ? structuredJSON : null}
      hideFromSitemapAndIndex={props.seo.hideFromSitemapAndIndex}
    />
  );
};
