import React, { FC } from 'react';
import Head from 'next/head';
import { ICanonical } from '../../interfaces/canonical.interface';
import getConfig from 'next/config';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import i18nConfig from '../../../next-config/i18n.config.js';
import { useRouter } from 'next/router';

const { publicRuntimeConfig } = getConfig();

const faviconFolderPath = '/static/favicon/' + publicRuntimeConfig.theme + '/';

export interface ISeoProps {
  pageTitle: string;
  title: string;
  description: string;
  url: string;
  canonicals: ICanonical[];
  image?: {
    url: string;
    alt: string;
  };
  structuredJSON?: string | null;
  hideFromSitemapAndIndex?: boolean;
}

const buildCanonicalUrl = (canonical: ICanonical): React.ReactElement | null => {
  let url = `/${canonical.lang}${canonical.url}`;

  if ('de' === canonical.lang) {
    url = canonical.url;
  }

  const canonicalUrl = `${publicRuntimeConfig.appBaseCanonicalUrl}${url}`;

  // Hide not activated languages.
  if (false === i18nConfig.locales.includes(canonical.lang)) {
    return null;
  }

  return (<link
    key={`canonical-${canonical.lang}`}
    rel={canonical.alternate ? 'alternate' : 'canonical'}
    href={canonicalUrl}
  />);
};

const SEO: FC<ISeoProps> = ({
  pageTitle,
  title,
  description,
  url,
  canonicals,
  image,
  structuredJSON,
  hideFromSitemapAndIndex = false,
}) => {

  const router = useRouter();
  const metaUrl = (): string => {

    if (1 < url?.length) {

      return url;
    }

    if (0 < canonicals?.length) {

      return canonicals.map((link) =>link.url).toString();
    }

    return router.asPath;
  };

  return (
    <Head>
      <title>{pageTitle}</title>


      <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover, interactive-widget=resizes-content" />

      {hideFromSitemapAndIndex && (
        <meta name="robots" content="noindex, nofollow" />
      )}

      {structuredJSON && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredJSON }} />
      )}

      <link rel='manifest' href={faviconFolderPath + 'manifest.json'} />

      <link rel='apple-touch-icon' sizes='57x57' href={faviconFolderPath + 'apple-icon-57x57.png'}/>
      <link rel='apple-touch-icon' sizes='60x60' href={faviconFolderPath + 'apple-icon-60x60.png'} />
      <link rel='apple-touch-icon' sizes='72x72' href={faviconFolderPath + 'apple-icon-72x72.png' }/>
      <link rel='apple-touch-icon' sizes='76x76' href={faviconFolderPath + 'apple-icon-76x76.png'} />
      <link rel='apple-touch-icon' sizes='114x114' href={faviconFolderPath + 'apple-icon-114x114.png'} />
      <link rel='apple-touch-icon' sizes='120x120' href={faviconFolderPath + '/apple-icon-120x120.png'} />
      <link rel='apple-touch-icon' sizes='144x144' href={faviconFolderPath + 'apple-icon-144x144.png'} />
      <link rel='apple-touch-icon' sizes='152x152' href={faviconFolderPath + 'apple-icon-152x152.png'} />
      <link rel='apple-touch-icon' sizes='180x180' href={faviconFolderPath + 'apple-icon-180x180.png'} />
      <link rel='icon' type='image/png' sizes='16x16' href={faviconFolderPath + 'favicon-16x16.png'} />
      <link rel='icon' type='image/png' sizes='32x32' href={faviconFolderPath + 'favicon-32x32.png'} />
      <link rel='icon' type='image/png' sizes='96x96' href={faviconFolderPath + 'favicon-96x96.png'} />
      <link rel='icon' type='image/png' sizes='36x36' href={faviconFolderPath + 'android-icon-36x36.png'} />
      <link rel='icon' type='image/png' sizes='48x48' href={faviconFolderPath + 'android-icon-48x48.png'} />
      <link rel='icon' type='image/png' sizes='72x72' href={faviconFolderPath + 'android-icon-72x72.png'} />
      <link rel='icon' type='image/png' sizes='96x96' href={faviconFolderPath + 'android-icon-96x96.png'} />
      <link rel='icon' type='image/png' sizes='128x128' href={faviconFolderPath + 'android-icon-128x128.png'} />
      <link rel='icon' type='image/png' sizes='192x192' href={faviconFolderPath + 'android-icon-192x192.png'} />

      <meta property='og:title' content={title}/>
      <meta name='twitter:title' content={title}/>

      <meta name='description' key='description' content={description}/>
      <meta property='og:description' content={description}/>
      <meta name='twitter:description' content={description}/>

      {image && (
        <>
          <meta name='twitter:image' content={image.url}/>
          <meta name='image' content={image.url}/>
          <meta property='og:image' content={image.url}/>
          <meta property='og:image:alt' content={image.alt}/>
        </>
      )}

      <meta property='og:url' content={`${publicRuntimeConfig.appBaseUrl}${metaUrl()}`}/>
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content="APPID"/>

      <meta name='twitter:card' content='summary_large_image'/>
      <meta name='twitter:creator' content={'digando.ready2rent'}/>

      {canonicals?.map((canonical) => (buildCanonicalUrl(canonical)))}
    </Head>
  );
};

export default SEO;
