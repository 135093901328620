import React, { FC } from 'react';
import { TenantLogoFragment } from '../../@types/codegen/graphql';
import NextImage from 'next/image';

export const TenantLogo: FC<{ 
  logo: TenantLogoFragment; 
  height?: number; 
  width?: number; 
}> = ({ logo, height, width }) => {
  if (!logo) {
    return null
  }

  if ('svg' === logo.metadata.format) {
    return (
      <NextImage
        src={`${logo.url}${height ? `?h=${height}` : ''}`}
        priority={true}
        unoptimized={true}
        alt={logo.alt}
        title={logo.title}
        width={width ?? logo.metadata.width}
        height={height ?? logo.metadata.height}
      />
    );
  }

  return (
    <NextImage
      src={`${logo.url}${height ? `?h=${height}` : '?w=450&fm=webp'}`}
      priority={true}
      unoptimized={true}
      alt={logo.alt}
      title={logo.title}
      width={width ?? logo.metadata.width}
      height={height ?? logo.metadata.height}
    />
  );
};
